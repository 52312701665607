<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Request Data') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section v-if="object.options && (object.options.title || object.options.description)" class="text-center q-pt-none">
        <div class="text-subtitle1">
          {{ object.options.title }}
        </div>

        <div class="text-caption">
          {{ object.options.description }}
        </div>
      </q-card-section>

      <q-card-section class="text-center q-pt-none">
        <form-builder :schema="schema" />
      </q-card-section>


      <q-card-section v-if="places && places.length > 0" class="text-center q-pt-none">
        <div v-if="places" class="q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
          <q-table
              row-key="id"
              :rows="places"
              :columns="columns"
              v-model:pagination="pagination"
              :rows-per-page-options="[]"
              table-header-class="d-none"
              virtual-scroll
              binary-state-sort
              flat
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <q-tr
                  :props="props"
              >
                <q-td
                    key="image"
                    :props="props"
                    auto-width
                >
                  <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[props.row.state]}`">
                    {{ $t(stateName[props.row.state] || props.row.state) }}
                  </label>
                </q-td>

                <q-td>
                  <h5 class="text-center">{{ props.row.count }}</h5>
                </q-td>

                <q-td
                    key="details"
                    :props="props"
                    style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
                >
                  <div class="text-subtitle1 items-center">
                    <span v-if="props.row._embedded?.place">
                      <place-object
                          :data="props.row._embedded.place"
                          :type="props.row._embedded.place.type"
                          class="q-mb-sm"
                          @click="openPlace(props.row._embedded.place)"
                      />
                    </span>
                  </div>

                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="light-blue-9"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Save')"
          @click="save"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Close')"
          @click="close"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'RequestDataModal',
  emits: ['submit', 'close'],
  data () {
    return {
      isOpen: false,
      model: {},
      stateName:null,
      itemStates: null,
      columns : [],
      places : [],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      object: {
        data: []
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            fields: this.object.data.map(field => {
              return this.$utils.field.create(this.model, field)
            })
          }
        ]
      }
    }
  },
  methods: {
    openPlace (place) {

      if( place.barcode && this.object?.data) {
        const lastIndex = place.barcode.endsWith('*')
            ? place.barcode.length - 1
            : place.barcode.length
        let str = place.barcode.slice(0, lastIndex)
        this.model[this.object.data[0].name] = str
        this.save()
      }

    },
    close () {
      this.isOpen = false
      this.$emit('close')
    },
    open (object , places = [], columns = [] ,pagination = {},itemStates = {}, stateName = {}) {
      this.object = object

      this.places = places
      this.columns = columns
      this.pagination = pagination
      this.itemStates = itemStates
      this.stateName = stateName

      if (!this.object.data) {
        this.object.data = []
      }

      this.isOpen = true
    },
    save () {
      const data = {
        ...this.model,
        event: this.object.event,
        entityClass: this.object.entityClass
      }

      this.isOpen = false
      this.model = {}
      this.$emit('submit', data)
    }
  }
}
</script>
